
import { Component } from 'vue';
import DefaultSettingsPage from '@/components/Settings/DefaultSettings/Page.vue';
import PeerGroupPage from '@/components/Settings/PeerGroup/Page.vue';
import AreaManagerPage from '@/components/Settings/AreaManager/Page.vue';
import SettingsList from '@/components/Settings/List.vue';
import CompanyInfoPage from '@/components/Settings/CompanyInfo/Page.vue';
import { VIEW_COMPANIES, UPDATE_COMPANIES } from '@/consts/permissions';
import each from 'lodash/each';
import { checkAuthorities } from '@/util/navigation';
import { mapState } from 'vuex';
import { checkPermission } from '@/api/utils';

interface Menu {
  name: string;
  title: string;
  component: Component;
  authorityCodes?: string[];
}

const settingMenus: Menu[] = [
  {
    name: 'settings:default',
    title: 'Default Settings',
    component: DefaultSettingsPage
  },
  {
    name: 'settings:peer-group',
    title: 'Peer Group',
    component: PeerGroupPage
  },
  {
    name: 'settings:area-manager',
    title: 'Area Manager',
    component: AreaManagerPage
  },
  {
    name: 'settings:company',
    title: 'Company Info',
    component: CompanyInfoPage,
    authorityCodes: [VIEW_COMPANIES, UPDATE_COMPANIES]
  }
];

interface MenuMap {
  [key: string]: Menu;
}

const menuMap: MenuMap = {};
each(settingMenus, menu => {
  menuMap[menu.name] = menu;
});

export default {
  components: { SettingsList },
  data() {
    return {
      settingMenus: [
        {
          name: 'settings:default',
          title: this.$t('SettingsManagement.DefaultSettings'),
          component: DefaultSettingsPage
        },
        {
          name: 'settings:peer-group',
          title: this.$t('SettingsManagement.PeerGroup'),
          component: PeerGroupPage
        },
        {
          name: 'settings:area-manager',
          title: this.$t('SettingsManagement.AreaManager'),
          component: AreaManagerPage
        },
        {
          name: 'settings:company',
          title: this.$t('SettingsManagement.CompanyInfo'),
          component: CompanyInfoPage,
          authorityCodes: [VIEW_COMPANIES, UPDATE_COMPANIES]
        }
      ],
      selectedIndex: -1,
      activeTitle: '',
      activeComponent: null,
      settingMenusList: [],
      default: {
        name: 'settings:default',
        title: this.$t('SettingsManagement.DefaultSettings'),
        component: DefaultSettingsPage
      },
      peerGroup: {
        name: 'settings:peer-group',
        title: this.$t('SettingsManagement.PeerGroup'),
        component: PeerGroupPage
      },
      areaManager: {
        name: 'settings:area-manager',
        title: this.$t('SettingsManagement.AreaManager'),
        component: AreaManagerPage
      },
      company: {
        name: 'settings:company',
        title: this.$t('SettingsManagement.CompanyInfo'),
        component: CompanyInfoPage,
        authorityCodes: [VIEW_COMPANIES, UPDATE_COMPANIES]
      }
    };
  },
  async mounted() {
    const name = this.$router.currentRoute.name;
    const menu = menuMap[name];
    if (!menu) {
      throw new Error(`Unmapped route name: ${name}`);
    }
    this.activeComponent = menu.component;
    this.selectedIndex = this.settingMenus.indexOf(menu);
    // this.viewAllowed('DefaultSettings') &&
    //   this.settingMenusList.push(this.default);
    this.viewAllowed('FlowPeerGroupManagementView') &&
      this.settingMenusList.push(this.peerGroup);
    this.viewAllowed('FlowAreaManagerManagementView') &&
      this.settingMenusList.push(this.areaManager);
    this.viewAllowed('FlowCompanyInfoView') &&
      this.settingMenusList.push(this.company);
    const allowedMenus = await Promise.all(
      this.settingMenus.map(menu => {
        if (!menu.authorityCodes) return Promise.resolve(true);
        return checkAuthorities(menu.authorityCodes);
      })
    );
    this.settingMenus = this.settingMenus.filter(
      (_, index) => allowedMenus[index]
    );
    // this.settingMenusList = [
    //   this.default,
    //   this.peerGroup,
    //   this.areaManager,
    //   this.company
    // ];
  },
  methods: {
    viewAllowed(name) {
      return checkPermission(name);
    },
    handleNavigation(menu: Menu) {
      this.$router.push({ name: menu.name });
    }
  }
};
